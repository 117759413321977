export const restaurantColumns = [
  // {
  //   field: "id", headerName: "ID", width: 30,
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         {params.row.business_entity_id}
  //       </div>
  //     );
  //   }
  // },
  {
    field: "name",
    headerName: "Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={'https://cdn-icons-png.flaticon.com/128/272/272186.png'} alt="avatar" />
          {params.row.business_name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "address",
    headerName: "Address",
    width: 250,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === 'active' ? 'Active' : 'InActive'}
        </div>
      );
    },
  },
];



export const productColumns = [
  // { field: "id", headerName: "ID", width: 70, renderCell: (params) => {
  //   return (
  //     <div>
  //       {params.row.bepm_id}
  //     </div>
  //   );
  // }, },
  {
    field: "product",
    headerName: "Product Name",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={'https://cdn-icons-png.flaticon.com/128/8189/8189068.png'} alt="avatar" />
          {params.row.productName}
        </div>
      );
    },
  },
  {
    field: "name",
    headerName: "Linked Restaurant",
    width: 230,
    renderCell: (params) => {
      return (
        <div>
          {params.row.businessName}
        </div>

      );
    },
  },
  {
    field: "SDate",
    headerName: "Subscription Date",
    width: 180,
    renderCell: (params) => {
      return (
        <div>
          {'----------'}
        </div>
      );
    },
  },
  {
    field: "eDate",
    headerName: "Expiry Date",
    width: 180,
    renderCell: (params) => {
      return (
        <div>
          {'----------'}
        </div>
      );
    },
  },

];




export const userColumns = [
  // {
  //   field: "id", headerName: "ID", width: 30, renderCell: (params) => {
  //     return (
  //       <div>
  //         {params.row.userid}
  //       </div>
  //     );
  //   },
  // },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
        <img className="cellImg" src={'https://cdn-icons-png.flaticon.com/128/3024/3024605.png'} alt="avatar" />
        {params.row.name}
      </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 220,
    renderCell: (params) => {
      return (
        <div>
          {params.row.email}
        </div>
      );
    },
  },

  {
    field: "phone",
    headerName: "Phone No",
    width: 170,
    renderCell: (params) => {
      return (
        <div>
          {!params.row.phone ? '---' : params.row.phone}
        </div>
      );
    },
  },
  {
    field: "role",
    headerName: "Role",
    width: 120,
    renderCell: (params) => {
      return (
        <div>
          {params.row.role}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (params) => {
      return (
        <div>
          {params.row.status === 0 ? 'Inactive' : 'Active'}
        </div>
      );
    },
  }

];

