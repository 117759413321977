export const restaurantInputs = [
    {
      id: 1,
      label: "Restaurant Name",
      type: "text",
      placeholder: "Restaurant X",
    },
    {
      id: 2,
      label: "Manager ",
      type: "text",
      placeholder: "John Doe",
    },
    {
      id: 3,
      label: "Manager Email",
      type: "email",
    },
    {
      id: 4,
      label: "Restaurant Email",
      type: "mail",
      placeholder: "restaurant@buybyeq.com",
    },
    {
      id: 5,
      label: "Restaurant Phone",
      type: "text",
      placeholder: "+1 234 567 89",
    },
    {
      id: 6,
      label: "Address",
      type: "text",
      placeholder: "Bhubaneswar",
    },
    {
      id: 7,
      label: "Country ",
      type: "text",
      placeholder: "India",
    },
  ];
  
  export const productInputs = [
    {
      id: 1,
      label: "Title",
      type: "text",
      placeholder: "Apple Macbook Pro",
    },
    {
      id: 2,
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    {
      id: 3,
      label: "Category",
      type: "text",
      placeholder: "Computers",
    },
    {
      id: 4,
      label: "Price",
      type: "text",
      placeholder: "100",
    },
    {
      id: 5,
      label: "Stock",
      type: "text",
      placeholder: "in stock",
    },
  ];

  export const userInputs = [
    {
      id: 1,
      label: "Full Name",
      type: "text",
      placeholder: "User's Name",
    },
    {
      id: 2,
      label: "Email",
      type: "text",
      placeholder: "Email",
    },
    {
      id: 3,
      label: "Phone No",
      type: "text",
      placeholder: "Phone Number",
    },
    {
      id: 4,
      label: "Age",
      type: "text",
      placeholder: "age",
    },
  
  ];
  