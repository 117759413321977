import SectionSlider from "../../sections/common/home-sections/slider";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";

function HomePage() {

    useEffect(() => {
        loadScript("js/custom.js")
    })

    return (
        <>
            <SectionSlider />
        </>
    )
}

export default HomePage;