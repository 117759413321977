import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../../../../datatablesource";
import ConfirmDeleteModal from "../../../../../../../elements/modal/modal";
import "../../../../../../../../app/components/dashboard/components/datatable/datatable.scss";
import axios from "../../../../../../../../api/axios";

const UserDatatable = () => {
    const business_entity_id =sessionStorage.getItem("configBizEntityId");
    const [userData, setUserData] = useState([]);
    
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`businessEntities/getAllBusinessEntityUsers/${business_entity_id}`, {
              headers: { "Content-Type": "application/json" },
              withCredentials: false,
            });
           setUserData(response.data.allUsers);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [business_entity_id,userData]);

    const handleDelete = (id) => {
        // const setData = setUserData;
        // setData(userData.filter((item) => item.id !== id));
        // setSelectedRow(null);
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 250,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        {/* <Link
                            to="/config/restaurants/test"
                            style={{ textDecoration: "none" }}
                        >
                            <div className="viewButton">View</div>
                        </Link> */}
                        <div
                            className="editButton"

                        >
                            Edit
                        </div>
                        <div
                            className={
                                params.row.status === "active" ? "blockButton" : "activateButton"
                            }

                        >
                            {params.row.status === "active" ? "Inactive" : "Activate"}
                        </div>
                        <div
                            className="deleteButton"
                            onClick={() => setSelectedRow(params.row)} 
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="datatable">
            <div className="datatableTitle">
                All Users
            </div>
            <DataGrid
                className="datagrid"
                getRowId={(row) => row.userid}
                rows={userData}
                columns={userColumns.concat(actionColumn)}
                pageSize={9}
                rowsPerPageOptions={[9]}
            />


            {selectedRow && (
                <ConfirmDeleteModal
                    setOpenModal={setSelectedRow}
                    message={`Are you sure you want to delete "${selectedRow.username}"?`}
                    description={'You won\'t be able to revert this!'}
                    onDelete={() => handleDelete(selectedRow.id)}
                />
            )}
        </div>
    );
};

export default UserDatatable;
