import React, { useEffect, useRef, useState } from 'react';
import axios from '../../../../../api/axios';
import './newsub.scss';

const LinkRestoToProduct = ({ showPopup }) => {
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [restaurants, setRestaurants] = useState([]);
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState("");
    const userData = JSON.parse(sessionStorage.getItem("userData"));

    useEffect(() => {
        setErrMsg("");
        const fetchData = async () => {
            try {
                const response = await axios.get(`businessEntities/getAllRestaurantsProductsByGroupId/${userData.Group_ID}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: false,
                });
                setProducts(response.data.products);
                setRestaurants(response.data.restaurants);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);



    const handleSave = async () => {
        if (selectedProduct && selectedRestaurant) {
            try {
                const response = await axios.post(`businessEntities/addProductForBusiness`, {
                    businessEntityId: selectedRestaurant, productId: selectedProduct,groupId:userData.Group_ID
                }, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: false,
                });
                if (response.data.status === 200) {
                    console.log(response);
                    showPopup(false);
                } else {
                    console.log(response);
                    showPopup(false)
                }
            } catch (error) {
                // console.error('Error fetching data:', error.response.status);
                if (error.response.status === 400) {
                    setErrMsg("Already linked the product to this restaurant")
                    setTimeout(function () {
                        setErrMsg("")
                    }, 3000);
                } else {
                    setErrMsg("Error occured!")
                    setTimeout(function () {
                        setErrMsg("")
                    }, 3000);
                    showPopup(false)
                }
            }
        } else {
            setErrMsg("Select a restaurant and product!")
            setTimeout(function () {
                setErrMsg("")
            }, 3000);
        }
    };


    return (
        <>
            {showPopup && (
                <div className="overlay">
                    <div className="popup">
                        <div className="container">
                            <p
                                ref={errRef}
                                className={errMsg ? "errmsg-login" : "offscreen"}
                                aria-live="assertive"
                            >
                                {errMsg}
                            </p>
                            <h2 className="droptitle">Select Restaurant:</h2>
                            <select className="dropdown" value={selectedRestaurant} onChange={(e) => setSelectedRestaurant(e.target.value)}>
                                <option value={null}>Select a restaurant</option>
                                {restaurants.map(restaurant => (
                                    <option key={restaurant.business_entity_id} value={restaurant.business_entity_id}>
                                        {restaurant.business_name}
                                    </option>
                                ))}
                            </select>

                            <h2 className="droptitle">Select Product:</h2>
                            <select className="dropdown" value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)}>
                                <option value={null}>Select a product</option>
                                {products.map(product => (
                                    <option key={product.product_id} value={product.product_id}>
                                        {product.product_name}
                                    </option>
                                ))}
                            </select>

                            <button className="save-btn" onClick={handleSave}>Save & Link</button>
                            <button className="cancel-btn" onClick={() => showPopup(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default  LinkRestoToProduct;
