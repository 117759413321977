import React, { useState } from "react";
import "./signuppage.css";
import BBQImage from "../../../elements/bbq-image";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import ErrorModal from "../../../../app/elements/modal/errormodal";
import axios from "../../../../api/axios";



let loginUrl = "loginweb/read";
function LoginPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [isError, setIsError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        loginUrl,
        {
          email: formData.email,
          password: formData.password
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );
      if (response.status === 200 && response.data.login === true) {
        sessionStorage.setItem('isAuthenticated', true);
        sessionStorage.setItem('userData', JSON.stringify(response.data.userData));
        navigate('/config');
      }
      else {
        setIsError(true);
        setTimeout(() => {
          setIsError(null);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
      setTimeout(() => {
        setIsError(null);
      }, 2000);
    }
  };

  return (
    <div>
      <div className="signup-container">
        <div className="signup-form">
          <div className="header">
            <div className="headerlogin">
              <BBQImage src="images/BBQ_LOGO.png" alt="#" className='header-logo-login cursor-pointer' />
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <p className={"offscreen"} aria-live="assertive"></p>
            <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                autoComplete="off"
                required
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                id="password"
                name="password"
                required
                placeholder="Enter Your password"
                value={formData.password}
                onChange={handleInputChange}
              />
            </div>

            <button className="button-enabled max-width" type="submit">
              Login To BuyByeQ
            </button>
          </form>
          <p className="login-link">
            Don't have an account? <Link to="/registration">Register Now</Link>
          </p>
        </div>
      </div>
      {isError && (
        <ErrorModal
          setOpenModal={setIsError}
          message={"Invalid Credentials/No Access"}
          description={'Please check your credentials and try again!'}
        />
      )}
    </div>
  );
}


export default LoginPage;
