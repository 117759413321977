import React from "react";
import "../modal/modal.scss";
import BBQImage from "../../elements/bbq-image";

function ErrorModal({ setOpenModal, message,description}) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
      <div style={{ width: '40px', height: '40px' }}>
          <BBQImage src="images/warning.png" alt="#" />
        </div>
        <div >
          <h1 className="modaltitle">{message}</h1>
        </div>
        <div className="body">
          <p>{description}</p>
        </div>
        <div className="footer">
          <button onClick={() => setOpenModal(false)} id="cancelBtn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorModal;
