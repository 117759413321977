import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import BBQImage from '../../elements/bbq-image';
import { bannerData } from '../../../globals/banner';
import Banner from '../../sections/common/banner';
import axios from '../../../api/axios';

function ProductGridPage() {
    const [products, setProducts] = useState([]);
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    const userData = JSON.parse(sessionStorage.getItem("userData"));


    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseProducts = await axios.post(`businessEntities/getAllProductsAndPlans`, { userid: userData !== null ? (userData.userId !== null ? userData.userId : null) : null }, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: false,
                });
                setProducts(responseProducts.data.products);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [setProducts, products]);


    return (
        <>
            <Banner _data={bannerData.products} />
            <div className="section-full p-t100 p-b0 bg-white mobile-page-padding">
                <div className="container">
                    <div className="masonry-outer mfp-gallery news-grid clearfix row">
                        {products.map(product => (
                            <div key={product.product_id} className="masonry-item col-lg-4 col-md-6 col-sm-12 m-b30">
                                <div className="sx-latest-post-st-1">
                                    <div className="sx-post-media sx-img-effect img-reflection">
                                        <img src={product.product_image} alt={product.product_name} />
                                    </div>
                                    <div className="sx-post-info p-t30">
                                        <div className="sx-post-title">
                                            <h4 className="post-title">{product.product_name}</h4>
                                        </div>
                                        {product.plans.length > 0 ? (
                                            <ul className="sx-post-meta">
                                                {product.plans.map(plan => (
                                                    <li key={plan.plan_id}>
                                                        <p className="">{plan.name}</p>
                                                        <NavLink >
                                                            <i className="fi fi-tr-sack-dollar" />{plan.price} INR/{plan.billing_cycle}
                                                        </NavLink>
                                                        <NavLink to={`/products/detail/${product.product_id}`}><i className="fi fi-tr-camera-movie" />Watch Video</NavLink>
                                                        {isAuthenticated ? <NavLink className="site-button icon"><i className="fa fa-long-arrow-right" />{plan.subscription_status === 1 ? "Already Subscribed" : "Subscribe Now"}</NavLink> : <></>}
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p>No plans available. Subscription disabled</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductGridPage;
