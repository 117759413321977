import { publicUrlFor } from "../../globals/constants";
import "../elements/isloading.scss";

function Loader() {
    return (
        <div className="overlay">
            <div className="is-loading">
                <img src={publicUrlFor("images/on-progress.png")} alt="#" />
                <div className="loading-dots">
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                </div>
            </div>
        </div>
    );
}

export default Loader;
