import React, { useRef, useState, useEffect } from "react";
import "./signuppage.css";
import BBQImage from "../../../elements/bbq-image";
import { Link } from "react-router-dom";
import axios from "../../../../api/axios";
import { useNavigate } from 'react-router-dom';

const registerUrl = "registration/start";

function RegistrationPage() {
  const navigate = useNavigate();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const fullNameRef = useRef(null);
  const businessNameRef = useRef(null);
  const contactRef = useRef(null);
  const addressRef = useRef(null);
  const emailRef = useRef(null);

  useEffect(() => {
    setErrMsg("");
    return () => {
      setErrMsg("");
    };
  }, []);

  const handleRegistration = async (event) => {
    event.preventDefault();


    const fullName = fullNameRef.current.value;
    const businessName = businessNameRef.current.value;
    const contact = contactRef.current.value;
    // const address = addressRef.current.value;
    const email = emailRef.current.value;
    if (fullName !== '' && businessName !== '' && contact !== '' && email !== '') {
      try {
        setSubmitting(true);
        const response = await axios.post(
          registerUrl,
          {
            businessName,
            Name: fullName,
            email,
            contactNo: contact
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: false,
          }
        );
        if (response.status === 200) {
          navigate('/login');
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          setErrMsg(error.response.data.error)
          setTimeout(function () {
            setErrMsg("")
          }, 3000);
        } else {
          setErrMsg("Error: Registration failed.")
          setTimeout(function () {
            setErrMsg("")
          }, 3000);
        }
      } finally {
        setSubmitting(false);
        setTimeout(() => setErrMsg(""), 3000)
        setTimeout(function () {
          setErrMsg("")
        }, 3000);
      }
    } else {
      setErrMsg("Please fill all required fields")
      setTimeout(function () {
        setErrMsg("")
      }, 3000);
    }
  };

  return (
    <div>
      <div className="signup-container ">
        <div className="signup-form">
          <div className="header">
            <div className="headerlogin">
              <BBQImage src="images/BBQ_LOGO.png" alt="#" className='header-logo-login cursor-pointer' />
            </div>
            <p
            ref={errRef}
            className={errMsg ? "errmsg-login" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          </div>
          <form>
            <div className="form-group">
              <input
                type="text"
                ref={fullNameRef}
                placeholder="Enter full name"
                autoComplete="off"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                ref={businessNameRef}
                placeholder="Enter business name"
                autoComplete="off"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                ref={contactRef}
                placeholder="Enter your contact"
                autoComplete="off"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                ref={addressRef}
                placeholder="Enter your address"
                autoComplete="off"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                ref={emailRef}
                placeholder="Enter your email"
                autoComplete="off"
                required
              />
            </div>

            <button className="button-enabled max-width" type="submit" onClick={handleRegistration} disabled={submitting}>
              {submitting ? 'Registering...' : 'Register Business'}
            </button>
          </form>
          <p className="login-link">
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default RegistrationPage;
