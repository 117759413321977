import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SectionCard from '../cards/section_card';
import Modal from '@mui/material/Modal';
import '../lists/section_lst.scss';
import EmptyIcon from "@mui/icons-material/MenuOpenTwoTone";
import axios from '../../../../../../../../api/axios';

function SectionList() {
  const [searchTerm, setSearchTerm] = useState('');
  const [sections, setSections] = useState([]);

  const business_entity_id = sessionStorage.getItem("configBizEntityId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`businessEntities/getAllSections/${business_entity_id}`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        });
        setSections(response.data.allSections);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [sections,business_entity_id]);

  const [openModal, setOpenModal] = useState(false);
  const [newSectionName, setNewSectionName] = useState('');
  const [newSectionDescription, setNewSectionDescription] = useState('');
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  const handleAddSection = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddSectionSubmit = async () => {
    if (!newSectionName.trim()) {
      setErrMsg("Section name is required.");
      setTimeout(function () {
        setErrMsg("");
      }, 3000);
      return;
    }

    try {
      const response = await axios.post(
        'businessEntities/addSection',
        {
          sectionName: newSectionName,
          sectionDescription: newSectionDescription,
          businessEntityId: sessionStorage.getItem("configBizEntityId")
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );

      if (response.status === 200) {
        setOpenModal(false);
        setNewSectionDescription('')
        setNewSectionName('')
      }
    } catch (error) {
      console.error("Error:", error);
      setErrMsg("Error: adding section failed");
      setTimeout(function () {
        setErrMsg("");
      }, 3000);
    }
  };


  const filteredSections = sections.filter(section =>
    section.section_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="wrap">
      <div className="roww">
        <div className="search">
          <input
            type="text"
            className="searchTerm"
            placeholder="Search sections"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={handleAddSection}
            style={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '10px' }}
          >
            Add Section
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: '#2196F3', color: 'white' }}
          >
            Upload CSV
          </Button>

        </div>
        {searchTerm !== '' && filteredSections.length === 0 && sections.length !== 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No match found for your section search!</span>
          </div>
        )}
        {sections.length === 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No sections have been added</span>
          </div>
        )}
      </div>
      <Grid container spacing={3}>
        {filteredSections.map(section => (
          <Grid item key={section.section_id} xs={12} sm={6} md={4} lg={3}>
            <SectionCard
              section={section.section_name}
              description={section.description}
            />
          </Grid>
        ))}
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="add-section-modal"
        aria-describedby="add-section-description"
      >
        <div className="modal-container">
          <h6 id="add-section-modal">Add New Section</h6>
          <div>
            <p
              ref={errRef}
              className={errMsg ? "errmsg-login" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <label htmlFor="section-name">Section Name:</label>
            <input
              id="section-name"
              type="text"
              value={newSectionName}
              onChange={(e) => setNewSectionName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="section-description">Section Description:</label>
            <textarea
              id="section-description"
              value={newSectionDescription}
              onChange={(e) => setNewSectionDescription(e.target.value)}
            />
          </div>
          <Button variant="contained" onClick={handleAddSectionSubmit}>Save Section</Button>
        </div>
      </Modal>
    </div>
  );
}

export default SectionList;
