import { useLocation } from 'react-router-dom';
import AppRoutes from "../routing/app-routes";
import HeaderMain from "../app/sections/common/header";
import Footer from '../app/sections/common/footer';

function RootLayout() {
    const location = useLocation();


    const shouldHide = location.pathname === "/login" || location.pathname === "/registration" || location.pathname.startsWith("/config");
    const shouldClear = location.pathname !== "/config/products/configure";
    shouldClear && sessionStorage.removeItem("configurePath");

    return (
        <>
            <div className="page-wraper">
                {!shouldHide && <HeaderMain />}
                <div className="page-content">
                    <AppRoutes />
                </div>
                {!shouldHide && <Footer />}
                <button className="scroltop">
                    <span className="fa fa-angle-up relative" id="btn-vibrate"></span>
                </button>
            </div>
        </>
    )
}

export default RootLayout;
