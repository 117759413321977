import React from "react";
import "../modal/modal.scss";

function ConfirmDeleteModal({ setOpenModal, message,description, onDelete }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          {/* <button onClick={() => setOpenModal(false)}>X</button> */}
        </div>
        <div >
          <h1 className="modaltitle">{message}</h1>
        </div>
        <div className="body">
          <p>{description}</p>
        </div>
        <div className="footer">
          <button onClick={() => setOpenModal(false)} id="cancelBtn">
            Cancel
          </button>
          <button onClick={() => { setOpenModal(false); onDelete(); }}>Yes, Proceed</button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDeleteModal;
