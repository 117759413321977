import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CategoryCard from './cards/category _card';
import Modal from '@mui/material/Modal';
import '../category/category-list.scss';
import EmptyIcon from "@mui/icons-material/MenuOpenTwoTone";
import axios from '../../../../../../../api/axios';

function CategoryList() {
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const business_entity_id = sessionStorage.getItem("configBizEntityId");
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`businessEntities/getAllMenuCategories/${business_entity_id}`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        });
        setCategories(response.data.allCategories);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [categories,business_entity_id]);



  const [openModal, setOpenModal] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryDescription, setNewCategoryDescription] = useState('');

  const handleAddCategory = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddCategorySubmit = async () => {
    if (!newCategoryName) {
      setErrMsg("Category name is required.");
      setTimeout(function () {
        setErrMsg("");
      }, 3000);
      return;
    }

    try {
      const response = await axios.post(
        'businessEntities/addMenuCategory',
        {
          menuName: newCategoryName,
          menuDescription: newCategoryDescription === '' ? 'Satisfying main dishes for a hearty meal.' : newCategoryDescription,
          businessEntityId: sessionStorage.getItem("configBizEntityId")
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );

      if (response.status === 200) {
        handleCloseModal();
        setNewCategoryDescription('')
        setNewCategoryName('')
      }
    } catch (error) {
      console.error("Error:", error);
      setErrMsg("Error: adding category failed");
      setTimeout(function () {
        setErrMsg("");
      }, 3000);
    }
  };



  const filteredCategories = categories.filter(category =>
    category.category_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="wrap">
      <div className="roww">
        <div className="search">
          <input
            type="text"
            className="searchTerm"
            placeholder="Search categories"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={handleAddCategory}
            style={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '10px' }}
          >
            Add Category
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: '#2196F3', color: 'white' }}
          >
            Upload CSV
          </Button>

        </div>
        {searchTerm !== '' && filteredCategories.length === 0 && categories.length !== 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No match found for your category search!</span>
          </div>
        )}
        {categories.length === 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No menu categories added yet</span>
          </div>
        )}
      </div>
      <Grid container spacing={3}>
        {filteredCategories.map(category => (
          <Grid item key={category.category_id} xs={12} sm={6} md={4} lg={3}>
            <CategoryCard
              category={category.category_name}
              description={category.description ?? 'Satisfying main dishes for a hearty meal.'}
            />
          </Grid>
        ))}
      </Grid>


      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="add-category-modal"
        aria-describedby="add-category-description"
      >
        <div className="modal-container">
          <h6 id="add-category-modal">Add New Category</h6>
          <div>
            <p
              ref={errRef}
              className={errMsg ? "errmsg-login" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <label htmlFor="category-name">Category Name:</label>
            <input
              id="category-name"
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="category-description">Category Description:</label>
            <textarea
              id="category-description"
              value={newCategoryDescription}
              onChange={(e) => setNewCategoryDescription(e.target.value)}
            />
          </div>
          <Button variant="contained" onClick={handleAddCategorySubmit}>Save Category</Button>
        </div>
      </Modal>
    </div>
  );
}

export default CategoryList;
