import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import '../lists/table-list.scss';
import EmptyIcon from "@mui/icons-material/MenuOpenTwoTone";
import axios from '../../../../../../../../api/axios';
import TableCard from '../cards/table _card';

function TablesList() {
  const business_entity_id = sessionStorage.getItem("configBizEntityId");
  const [searchTerm, setSearchTerm] = useState('');
  const [tables, setTables] = useState([]);
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  //modal
  const [openModal, setOpenModal] = useState(false);
  const [newItemName, setNewItemName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseCategories = await axios.get(`businessEntities/getAllSections/${business_entity_id}`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        });
        setSections(responseCategories.data.allSections);

        const responseTables = await axios.get(`businessEntities/getAllTables/${business_entity_id}`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        });
        setTables(responseTables.data.allTables);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [business_entity_id, tables,setSections, setTables]);


  const handleAddItem = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddItemSubmit = async () => {
    if (!newItemName || !selectedSection) {
      setErrMsg("Both table name and section are required.");
      setTimeout(function () {
        setErrMsg("");
      }, 3000);
      return;
    }

    try {
      const response = await axios.post(
        'businessEntities/addTable',
        {
          tableName: newItemName,
          sectionId: selectedSection,
          businessEntityId: sessionStorage.getItem("configBizEntityId")
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );

      if (response.status === 200) {
        setOpenModal(false);

      }
    } catch (error) {
      console.error("Error:", error);
      setErrMsg("Error: adding table failed");
      setTimeout(function () {
        setErrMsg("");
      }, 3000);
    }
  };



  const filteredItems = tables.filter(item =>
    item.table_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="wrap">
      <div className="roww">
        <div className="search">
          <input
            type="text"
            className="searchTerm"
            placeholder="Search table"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={handleAddItem}
            style={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '10px' }}
          >
            Add Table
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: '#2196F3', color: 'white' }}
          >
            Upload CSV
          </Button>
        </div>
        {searchTerm !== '' && filteredItems.length === 0 && tables.length !== 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No match found for your table search!</span>
          </div>
        )}
        {tables.length === 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No tables added yet</span>
          </div>
        )}

      </div>
      <Grid container spacing={3}>
        {filteredItems.map(item => (
          <Grid item key={item.menu_id} xs={12} sm={6} md={4} lg={3}>
            <TableCard
              tableName={item.table_name}
              section={item.section}
            />
          </Grid>
        ))}
      </Grid>


      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="add-item-modal"
        aria-describedby="add-item-description"
      >
        <div className="modal-container">
          <h6 id="add-item-modal">Add New Table</h6>
          <p
            ref={errRef}
            className={errMsg ? "errmsg-login" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <div>
            <label htmlFor="item-name">Item Name:</label>
            <input
              id="item-name"
              type="text"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
            />
          </div>
          <div className="formInput">
            <label>Section:</label>
            <select className="dropdown" value={selectedSection} onChange={(e) => setSelectedSection(e.target.value)}>
              <option value={null}>Select a section</option>
              {sections.map(e => (
                <option key={e.section_id} value={e.section_id}>
                  {e.section_name}
                </option>
              ))}
            </select>
            <Button style={{ float: 'right' }} variant="contained" onClick={handleAddItemSubmit}>Save Table</Button>
          </div>

        </div>
      </Modal>
    </div>
  );
}

export default TablesList;
