export const bannerData = {
    products: {
        background: "images/banner/darkbgg.jpg",
        title: "Products",
        crumb: "Our Products",
        watermark: "POS Solutions"
    },
    config: {
        background: "images/banner/darkbgg.jpg",
        title: "Configuration",
        crumb: "Configuration",
        watermark: "POS Solutions"
    },
    faq: {
        background: "images/banner/bnr-4.jpg",
        title: "Faq Page",
        crumb: "Faq Page",
        watermark: "FAQ"
    }
}