import { NavLink } from "react-router-dom";
import BBQImage from "../../elements/bbq-image";
import { useState } from "react";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import { useNavigate } from 'react-router-dom'

function HeaderMain() {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
    const navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/login');
    };

    const [isActive, setIsActive] = useState(false);

    function toggleNavClass() {
        setIsActive(!isActive)
    }

    useEffect(() => {
        loadScript("js/mobilenav.js")
    })

    return (
        <>
            <header className={"header-style2 site-header mobile-sider-drawer-menu full-navbar " + (isActive ? "active" : "")}>
                <div className="sticky-header main-bar-wraper navbar-expand-lg">
                    <div className="main-bar">
                        <div className="container clearfix">
                            <div className="logo-header">
                                <div className="logo-header-inner logo-header-one">
                                    <NavLink to="/">
                                        <BBQImage src="images/BBQ_LOGO.png" alt="#" />
                                    </NavLink>
                                </div>
                            </div>
                            {/* NAV Toggle Button */}
                            <button id="mobile-side-drawer"
                                data-target=".header-nav"
                                data-toggle="collapse"
                                className="navbar-toggler collapsed"
                                onClick={toggleNavClass}
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar icon-bar-first" />
                                <span className="icon-bar icon-bar-two" />
                                <span className="icon-bar icon-bar-three" />
                            </button>
                            {/* EXTRA NAV */}
                            <div className="extra-nav">
                                <div className="extra-cell">

                                    <ul className="list-unstyled social-bx d-flex flex-wrap align-content-center text-white ">
                                        <li><a href="https://www.facebook.com/"><i className="feather-facebook" /></a></li>
                                        <li><a href="https://twitter.com/"><i className="feather-twitter" /></a></li>
                                        <li><a href="https://www.linkedin.com/"><i className="feather-linkedin" /></a></li>
                                        <li><a href="https://www.instagram.com/"><i className="feather-instagram" /></a></li>
                                    </ul>
                                    <li>
                                    </li>
                                </div>

                            </div>
                            {/* EXTRA Nav */}
                            {/* MAIN NAVIGATION */}
                            <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse ">
                                <ul className=" nav navbar-nav ">
                                    <li className="active">
                                        <NavLink to="">Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about-us">Company</NavLink>
                                        <ul className="sub-menu">
                                            <li><NavLink to="/about-us">About BuyByeQ</NavLink></li>
                                            <li><NavLink to="/faq">Faq Page</NavLink></li>
                                            <li><NavLink to="/testimonials">Testimonials</NavLink></li>
                                        </ul>
                                    </li>
                                    {/* <li>
                                        <NavLink to="/accounting-and-renewal">Accounting & Renewal</NavLink>
                                    </li> */}
                                    {isAuthenticated ? <li>
                                        <NavLink to="/config">Configuration</NavLink>
                                    </li> : <></>}
                                    
                                    <li><NavLink to="/all-products">Products</NavLink></li>
                                    <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                                    {!isAuthenticated ? (
                                        <li><NavLink to="/login">Login</NavLink></li>
                                    ) : (
                                        <li><NavLink onClick={handleLogout}>Logout</NavLink></li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default HeaderMain;