import React from "react";
import "./widget.scss";
import PersonOutlinedIcon from "@mui/icons-material/Restaurant";
import ProductsOutlinedIcon from "@mui/icons-material/PropaneRounded";
import InactiveProductsOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { NavLink } from "react-router-dom";

const Widget = ({ type }) => {
  let data;
let restaurants=sessionStorage.getItem("resCount")??'0';
let products=sessionStorage.getItem("prodCount")??'0';

  switch (type) {
    case "restaurants":
      data = {
        title: "RESTAURANTS",
        route: '/config/restaurants',
        amount: restaurants,
        link: "View all restaurants",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "active-products":
      data = {
        title: "ACTIVE PRODUCTS",
        route: '/config/products',
        amount: products,
        link: "View all active products",
        icon: (
          <ProductsOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
      case "in-active-products":
        data = {
          title: "INACTIVE PRODUCTS",
          route: '/config/products',
          amount: 0,
          link: "View all inactive products",
          icon: (
            <InactiveProductsOutlinedIcon
              className="icon"
              style={{
                backgroundColor: "rgba(255, 0, 0, 0.2)",
                color: "red",
              }}
            />
          ),
        };
        break;
    default:
      break;
  }

  return (
    <div className="widget_db">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "$"} {data.amount}
        </span>
       <NavLink  to={data.route}><span className="link">{data.link}</span></NavLink> 
      </div>
      <div className="right">
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
