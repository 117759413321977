import "../../../../../../../app/components/dashboard/pages/list/list.scss"
import UserDatatable from "./helpers/user_data_table"

const SpecialUsersList = () => {
  return (
    <div className="list">
      <div className="listContainer">
        <UserDatatable/>
      </div>
    </div>
  )
}

export default SpecialUsersList