import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import axios from "../../../../../api/axios";
import { useNavigate } from 'react-router-dom';


let newRestaurantUrl = "businessEntities/addBusinessEntity";

const New = ({ inputs, title }) => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const handleChange = (e, id) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const submiRequest = async (e) => {
    e.preventDefault();
    if (title == "Add New Restaurant") {
      const userData = JSON.parse(sessionStorage.getItem("userData"))
      try {
        const result = await axios.post(
          newRestaurantUrl,
          {
          "businessEntityId": userData.businessEntityId,
          "businessName": formData[1],
          "Email": formData[4],
          "contactNo": formData[5],
          "Address": formData[6],
          "Group_ID": userData.Group_ID,
          "Business_type": userData.business_type,
          "Manager": formData[2],
          "ManagerEmail": formData[3],
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      )
        if (result.status === 200) {
          console.log("Added");
          navigate('/config/restaurants');

        }
      } catch (error) {
        console.log("Error");
      }
    }
    console.log(formData[1]);
  }
  const [file, setFile] = useState("");

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor="file">
                  Restaurant Logo: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    type={input.type}
                    placeholder={input.placeholder}
                    value={formData[input.id] || ''}
                    onChange={(e) => handleChange(e, input.id)}
                  />
                </div>
              ))}
              <button onClick={submiRequest}>Save Restaurant</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;