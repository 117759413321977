import { NavLink } from "react-router-dom";
import BBQImage from "../../elements/bbq-image";

function Footer() {
    return (
        <>
            <footer className="site-footer footer-dark">
                <div className="sx-f-nl-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <h2 className="sx-f-title">
                                    Want to join BuyByeQ.
                                </h2>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="sx-nl-form">
                                    <form>
                                        <div className="sx-nl-form-inner">
                                            <input type="text" className="sx-nl-form-input" placeholder="info@buybyeq.com" />
                                            <button className="sx-nl-form-btn"><i className="flaticon-back-left" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* FOOTER BLOCKES START */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* ABOUT COMPANY */}
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                                <div className="widget widget_about">
                                    <div >
                                    <h4 className="sx-f-title">BuyByeQ POS</h4>
                                    </div>
                                    <p>Where innovation meets seamless transactions and business growth unfolds.
A dynamic force in POS technology and business optimization, we redefine efficiency, delivering impactful solutions and setting new standards for client satisfaction.</p>
                                    <div className="widget_about-call-section">
                                        <div className="sx-f-call-icon"><i className="flaticon-telephone" /></div>
                                        <div className="sx-f-call-section">
                                            <span>Contact us 24/7</span>
                                            <a href="tel:+55(990)66622">+9147 (013) 961 01</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* EXPLORE LINKS */}
                            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-6 col">
                                <div className="widget widget_services">
                                    <h4 className="sx-f-title">Explore Links</h4>
                                    <ul>
                                        <li><NavLink to="/services">Our Products</NavLink></li>
                                        <li><NavLink to="/team">FAQ</NavLink></li>
                                        <li><NavLink to="/contact-us">Contact</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                           
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 col-12 col footer-col-3">
                                <div className="widget widget_info">
                                    <h4 className="sx-f-title">Contacts</h4>
                                    <ul>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-phone" />
                                            </div>
                                            <div className="widget-info-detail">
                                                <a href="tel:+55(66633)2566">+91 (013) 961 01</a>
                                                <a href="tel:+55(66633)2577">+91 (013) 961 01</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-email" />
                                            </div>
                                            <div className="widget-info-detail">
                                                <p>info@buybyeq.com</p>
                                            </div>
                                        </li>
                                    
                                    </ul>
                                </div>
                            </div>
                            {/* CONTACT US */}
                
                        </div>
                    </div>
                </div>
                <div className="sx-f-bottom-section">
                    <div className="sx-f-social">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/">
                                    <i className="flaticon-facebook" />
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/">
                                    <i className="flaticon-twitter" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/">
                                    <i className="flaticon-linkedin" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/">
                                    <i className="flaticon-instagram" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="sx-f-copy">
                        © 2024 by <NavLink to="/index">BuyByeQ.</NavLink> All rights reserved.
                    </div>
                </div>
                {/* FOOTER COPYRIGHT */}
            </footer>

        </>
    )
}

export default Footer;