import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItemCard from '../cards/menu _card';
import Modal from '@mui/material/Modal';
import '../lists/menu-list.scss';
import EmptyIcon from "@mui/icons-material/MenuOpenTwoTone";
import axios from '../../../../../../../../api/axios';

function MenuItemList() {
  const business_entity_id = sessionStorage.getItem("configBizEntityId");
  const [searchTerm, setSearchTerm] = useState('');
  const [menuItems, setMenuItems] = useState([]);
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  //modal
  const [openModal, setOpenModal] = useState(false);
  const [newItemName, setNewItemName] = useState('');
  const [newItemPrice, setNewItemPrice] = useState('');
  const [newItemCGST, setNewItemCGST] = useState('');
  const [newItemSGST, setNewItemSGST] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseCategories = await axios.get(`businessEntities/getAllMenuCategories/${business_entity_id}`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        });
        setCategories(responseCategories.data.allCategories);

        const responseMenuItems = await axios.get(`businessEntities/getAllMenuItems/${business_entity_id}`, {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        });
        setMenuItems(responseMenuItems.data.allMenus);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [business_entity_id, menuItems, setCategories, setMenuItems]);


  const handleAddItem = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddItemSubmit = async () => {
    if (!newItemName || !newItemPrice || !newItemCGST || !newItemSGST || !selectedCategory) {
      setErrMsg("All fields are required.");
      setTimeout(function () {
        setErrMsg("");
      }, 3000);
      return;
    }

    try {
      const response = await axios.post(
        'businessEntities/addMenuItem',
        {
          menuName: newItemName,
          cgst: newItemCGST,
          sgst: newItemSGST,
          price: newItemPrice,
          menu_category_id: selectedCategory,
          businessEntityId: sessionStorage.getItem("configBizEntityId")
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );

      if (response.status === 200) {
        setOpenModal(false);
        setNewItemName('');
        setNewItemPrice('');
        setNewItemCGST('');
        setSelectedCategory(null);
        setNewItemSGST('');
      }
    } catch (error) {
      console.error("Error:", error);
      setErrMsg("Error: adding menu failed");
      setTimeout(function () {
        setErrMsg("");
      }, 3000);
    }
  };


  const filteredItems = menuItems.filter(item =>
    item.menu_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="wrap">
      <div className="roww">
        <div className="search">
          <input
            type="text"
            className="searchTerm"
            placeholder="Search items"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={handleAddItem}
            style={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '10px' }}
          >
            Add Item
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: '#2196F3', color: 'white' }}
          >
            Upload CSV
          </Button>
        </div>
        {searchTerm !== '' && filteredItems.length === 0 && menuItems.length !== 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No match found for your menu search!</span>
          </div>
        )}
        {menuItems.length === 0 && (
          <div className="no-match-found">
            <EmptyIcon className="icon" />
            <span className="message">No menu items added yet</span>
          </div>
        )}

      </div>
      <Grid container spacing={3}>
        {filteredItems.map(item => (
          <Grid item key={item.menu_id} xs={12} sm={6} md={4} lg={3}>
            <MenuItemCard
              itemName={item.menu_name}
              price={item.price}
              cgst={item.cgst}
              sgst={item.sgst}
            />
          </Grid>
        ))}
      </Grid>


      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="add-item-modal"
        aria-describedby="add-item-description"
      >
        <div className="modal-container">
          <h6 id="add-item-modal">Add New Item</h6>
          <p
            ref={errRef}
            className={errMsg ? "errmsg-login" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <div>
            <label htmlFor="item-name">Item Name:</label>
            <input
              id="item-name"
              type="text"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="item-price">Price:</label>
            <input
              id="item-price"
              type="number"
              value={newItemPrice}
              onChange={(e) => setNewItemPrice(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="item-cgst">CGST:</label>
            <input
              id="item-cgst"
              type="number"
              value={newItemCGST}
              onChange={(e) => setNewItemCGST(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="item-sgst">SGST:</label>
            <input
              id="item-sgst"
              type="number"
              value={newItemSGST}
              onChange={(e) => setNewItemSGST(e.target.value)}
            />
          </div>
          <div className="formInput">
            <label>Menu Category:</label>
            <select className="dropdown" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
              <option value={null}>Select a category</option>
              {categories.map(e => (
                <option key={e.category_id} value={e.category_id}>
                  {e.category_name}
                </option>
              ))}
            </select>
            <Button style={{ float: 'right' }} variant="contained" onClick={handleAddItemSubmit}>Save Item</Button>
          </div>

        </div>
      </Modal>
    </div>
  );
}

export default MenuItemList;
