import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../cards/table_card.scss';

function TableCard({
   tableName,
   section,
    onEdit,
    onDelete
}) {
    return (
        <Card className="menu-item-card" variant="outlined">
            <CardContent>
                <Typography variant="h5" component="h2">
                    {tableName}
                </Typography>
                <Typography variant="p" component="p">
                    ({section})
                </Typography>
                <div className="button-container">
                    <IconButton onClick={onEdit} aria-label="edit" className="edit-button">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={onDelete} aria-label="delete" className="delete-button">
                        <DeleteIcon />
                    </IconButton>
                </div>
            </CardContent>
        </Card>
    );
}

export default TableCard;
